import gql from 'graphql-tag'
import chatFragment from './../ChatFragment'

export const CHATS_QUERY_KEN = gql`
  query getChatMessageSuport_K($room: String) {
    chats: getChatMessageSuport_K(room: $room) {
      ...${chatFragment}
    }
  }
`

export const CHATS_ROOM_QUERY_KEN = gql`
  query getReciveMessageSuport_K {
    recivedChats_k: getReciveMessageSuport_K {
      room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
          email
        }
      }
    }
  }
`